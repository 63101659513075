.latest-news {
  &.primary, &-primary-contrast {
    .latest-news__title,
    .latest-news__link {
      color: var(--primary);
    }

    &-contrast {
      background-color: var(--gray-400);
    }
  }

  &.secondary, &.secondary-contrast {
    .latest-news__title,
    .latest-news__link {
      color: var(--secondary) !important;
    }

    &-contrast {
      background-color: var(--gray-400);
    }
  }
}
