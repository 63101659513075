.LinkInternal {
  &.link-internal {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
    border: none;
    background: transparent;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }

    i {
      font-size: 1rem;
    }

    &.typecolor-primary {
      color: var(--primary);
      &:hover {
        color: #006c41;
      }
    }
    &.typecolor-secondary {
      color: var(--secondary);
      &:hover {
        color: #341050;
      }
    }
    &.typecolor-success {
      color: var(--success);
      &:hover {
        color: #9fbe43;
      }
    }
    &.typecolor-white {
      color: var(--white);
      &:hover {
        color: var(--success);
      }
    }
    &.typecolor-purple {
      color: var(--purple);
      &:hover {
        color: #931f80;
      }
    }
    &.typecolor-orange {
      color: var(--orange);
      &:hover {
        color: #da620a;
      }
    }
    &.typecolor-pink {
      color: var(--pink);
      &:hover {
        color: #d61349;
      }
    }
    &.typecolor-green-dark-100 {
      color: var(--green-dark-100);
      &:hover {
        color: var(--green-dark-300);
      }
    }
    &.typecolor-gray-100 {
      color: var(--gray-100);
      &:hover {
        color: var(--gray-300);
      }
    }
  }
}
