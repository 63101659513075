@import './../../../styles/utils';

.card-news {
  img {
    width: 100%;
    height: 130px;
    border-radius: 16px;
    margin-bottom: 20px;
    object-fit: cover;

    @media screen and (min-width: 768px) {
      height: 237px;
    }
  }

  .card-news-date {
    font-size: 0.888rem;
    color: var(--secondary);
    font-stretch: condensed;
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: 0.18px;
    margin-bottom: 15px;
    font-family: 'UnimedSans-2020-BookCondensed', Arial, sans-serif;
  }

  .card-news-title {
    font-size: 1.333rem;
    font-weight: bold;
    line-height: 1.2;
    color: var(--black);
    margin-bottom: 15px;
    font-family: 'UnimedSans-2020-Bold', Arial, sans-serif;
  }

  .card-news-preview {
    line-height: 1.3;
    letter-spacing: 0.25px;
    color: var(--gray-100);
    font-size: 1rem;
    font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
  }

  &:hover {
    .card-news-title,
    .card-news-preview {
      text-decoration: underline;
    }
  }
}

.highlight {
  .card-news-date {
    @media screen and (min-width: 768px) {
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      font-size: remDesktop(16px);
      font-weight: normal;
      font-stretch: condensed;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.18px;
      color: var(--primary);
    }
  }

  .card-news-title {
    @media screen and (min-width: 768px) {
      font-family: 'UnimedSans-2020-Bold', Arial, sans-serif;
      font-size: remDesktop(24px);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: var(--gray-200);
    }
  }

  .card-news-preview {
    @media screen and (min-width: 768px) {
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      font-size: remDesktop(18px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: 0.25px;
      color: var(--gray-100);
    }
  }

  img {
    @media screen and (min-width: 768px) {
      height: 320px;
      margin-bottom: 32px;
    }
  }
}

.reduced {
  border-top: 1px solid var(--gray-form);
  padding: 24px 0;

  .card-news-date {
    font-family: 'UnimedSans-2020-BookCondensed', Arial, sans-serif;
    font-size: remMobile(16px);
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.18px;
    color: var(--primary);
  }

  .card-news-title {
    font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
    font-size: remMobile(16px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--gray-200);

    @media screen and (min-width: 768px) {
      font-size: remDesktop(18px);
      font-weight: normal;
      line-height: 1.39;
      letter-spacing: 0.25px;
    }
  }

  img {
    @media screen and (min-width: 768px) {
      height: 320px;
    }
  }
}
